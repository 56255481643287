import { Button, Card, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { PrettyJSON } from '../../components/containers/pretty-json';
import * as Layout from '../../layouts/module-layout';
import { stuFetch } from '../../utils';

const QueryModule: React.FC = (props) => {
  const [jsonPayload, setJsonPayload] = useState<StucatzHttpRes>();
  const [searchTerm, setSearchTerm] = useState<string>();
  const [searchTermFull, setSearchTermFull] = useState<string>();
  const [recentSearches, setRecentSearches] = useState<string[]>([
    'game/nhl/metadata',
    'game/nhl/stat_categories',
    'game/nhl/game_weeks',
    'users;use_login=1/games',
    'users;use_login=1/games/leagues',
    'users;use_login=1/games/leagues/teams',
    'users;use_login=1/games;game_keys=nhl/teams',
    'league/403.l.43517/players',
    'players;player_keys=403.p.1644',
    'leagues;league_keys=403.l.43517',
    'league/403.l.43517/scoreboard;week=14',
    'users;use_login=1/games/leagues/teams;out=standings',
    'users;use_login=1/games/leagues;league_key=210.l.13958/players',
    'leagues;league_keys=210.l.13958/standings',
    'game/nhl;out=metadata,players,game_weeks,stat_categories,position_types,roster_positions',
    'league/403.l.43517;out=metadata,settings,standings,scoreboard,teams,players,draftresults,transactions',
  ]);

  const queryHandler = () => {
    const queryInput = searchTerm as string;

    setRecentSearches((prev) => {
      const updatedTermsArr = [...prev];
      updatedTermsArr.unshift(queryInput);
      return updatedTermsArr.slice(0, 15);
    });

    stuFetch<StucatzHttpRes>('/api/fantasy/query', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
      body: JSON.stringify({
        uri:
          'https://fantasysports.yahooapis.com/fantasy/v2/' +
          queryInput +
          '?format=json',
      }),
    })
      .then((data) => {
        setJsonPayload((prev) => data);
      })
      .catch((err) => {
        setJsonPayload((prev) => err);
      });
  };

  const queryHandlerFull = () => {
    const queryInput = searchTermFull as string;

    fetch('/api/fantasy/query', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
      body: JSON.stringify({
        uri: queryInput + '?format=json',
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setJsonPayload((prev) => data);
      })
      .catch((err) => {
        setJsonPayload((prev) => err);
      });
  };

  const recentSearchTermHandler = (searchItem: string) => {
    setSearchTerm((prev) => searchItem);
  };

  return (
    <Layout.ModuleLayout>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexFlow: 'wrap',
        }}
      >
        <section
          style={{
            width: '800px',
          }}
        >
          <h2>Query Testing</h2>
          <TextField
            id="standard-basic"
            label="Request URI"
            style={{ width: '100%' }}
            value={searchTerm}
            InputProps={{
              startAdornment: 'https://fantasysports.yahooapis.com/fantasy/v2/',
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearchTerm((prev) => e.target.value);
            }}
            onKeyDown={(e: React.KeyboardEvent) => {
              if (e.keyCode === 13) {
                queryHandler();
              }
            }}
          />
          <TextField
            id="standard-basic"
            label="Request URI Full"
            style={{ width: '100%', margin: '10px 0' }}
            value={searchTermFull}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearchTermFull((prev) => e.target.value);
            }}
            onKeyDown={(e: React.KeyboardEvent) => {
              if (e.keyCode === 13) {
                queryHandlerFull();
              }
            }}
          />
          <div style={{ padding: '20px 0' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={(e: React.MouseEvent) => {
                queryHandler();
              }}
            >
              Fetch
            </Button>
          </div>
        </section>
        <section
          style={{
            maxWidth: '800px',
            width: '100%',
          }}
        >
          <h2>Recent</h2>
          <Card
            style={{
              width: '100%',
              fontSize: 'var(--font-size-xs)',
            }}
          >
            <div style={{ padding: '10px' }}>
              {recentSearches.map((searchItem) => {
                return (
                  <div
                    key={searchItem + Math.random()}
                    style={{ cursor: 'pointer' }}
                    onClick={recentSearchTermHandler.bind(null, searchItem)}
                    className="df"
                  >
                    {searchItem}
                  </div>
                );
              })}
            </div>
          </Card>
        </section>
      </div>
      <PrettyJSON data={jsonPayload} />
    </Layout.ModuleLayout>
  );
};

export default QueryModule;
