import React, { useState } from 'react';
import { Burger } from '../../components/animated-burger';
import TheNav from '../TheNav';
import * as Styled from './styled';

const TheHeader: React.FC = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const burgerHandler = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <>
      <Styled.HeaderWrapper>
        <Styled.HeaderLogo>Stucatz.</Styled.HeaderLogo>
        {/* <div>
          <img
            src="https://stucatz-assets.s3.us-east-2.amazonaws.com/img/logos/stucatz.png"
            alt="Stucatz"
          />
        </div> */}
        <Burger isOpen={isOpen} onClick={burgerHandler} />
      </Styled.HeaderWrapper>
      <TheNav isOpen={isOpen} onClick={burgerHandler} />
      {/* {isOpen && <TheNav onClick={burgerHandler} />} */}
    </>
  );
};

export default TheHeader;
