import styled from 'styled-components';
import { SockStripeProps } from './';

export const SockLayout = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: space-around;
  background-color: var(--color-blue);
  color: var(--color-white);
  min-height: 100vh;
  min-width: 100vw;
  z-index: -1;
`;

export const SockStripe = styled.div<SockStripeProps>`
  display: flex;
  background-color: var(--color-white);
  height: ${(props: SockStripeProps) => props.height}px;
  width: 100%;
  margin: 20px 0;
  opacity: 0.8;
  z-index: inherit;
`;
