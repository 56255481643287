import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  HelmetIcon,
  HockeyNetIcon,
  HockeySticksIcon,
  MatchupsIcon,
} from '../../components/icons';
import * as Styled from './styled';
import './styles.css';

const navLinkStyles = {
  textDecoration: 'none',
};

export interface NavProps {
  isOpen: boolean;
  onClick: () => void;
}

export interface NavLayoutProps {
  isOpen: boolean;
}

const TheNav: React.FC<NavProps> = (props) => {
  return (
    <div>
      <Styled.NavLayout isOpen={props.isOpen}>
        <Styled.NavItemContainer onClick={props.onClick}>
          <NavLink
            activeClassName="navlink-active"
            style={navLinkStyles}
            to="/manager"
          >
            <Styled.NavItem>
              <HelmetIcon width={35} style={{ paddingRight: '15px' }} />
              <h4>Manager Hub</h4>
            </Styled.NavItem>
          </NavLink>

          <NavLink
            activeClassName="navlink-active"
            style={navLinkStyles}
            to="/matchups"
          >
            <Styled.NavItem>
              <MatchupsIcon width={35} style={{ paddingRight: '15px' }} />
              <h4>Matchups</h4>
            </Styled.NavItem>
          </NavLink>

          <NavLink
            activeClassName="navlink-active"
            style={navLinkStyles}
            to="/trades"
          >
            <Styled.NavItem>
              <HockeySticksIcon width={35} style={{ paddingRight: '15px' }} />
              <h4>Trades</h4>
            </Styled.NavItem>
          </NavLink>

          <NavLink
            activeClassName="navlink-active"
            style={navLinkStyles}
            to="/starters"
          >
            <Styled.NavItem>
              <HockeyNetIcon width={35} style={{ paddingRight: '15px' }} />
              <h4>Starters</h4>
            </Styled.NavItem>
          </NavLink>
        </Styled.NavItemContainer>
      </Styled.NavLayout>
    </div>
  );
};

export default TheNav;
