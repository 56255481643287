import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { PrivateRoute } from './components/router-utils';
import { UserProfileCtx } from './context/user';
import { TheFooter, TheHeader } from './global/';
import { Login, Manager, Matchups, Query, Starters, Trades } from './views';

const App: React.FC = (props) => {
  const [userProfileInfo, setUserProfileInfo] = useState<UserProfile | null>(
    null,
  );

  const authRoutes = () => {
    return (
      <>
        <TheHeader />
        <PrivateRoute exact path="/manager">
          <Manager />
        </PrivateRoute>
        <PrivateRoute exact path="/trades">
          <Trades />
        </PrivateRoute>
        <PrivateRoute exact path="/matchups">
          <Matchups />
        </PrivateRoute>
        <PrivateRoute exact path="/starters">
          <Starters />
        </PrivateRoute>
        <PrivateRoute exact path="/query">
          <Query />
        </PrivateRoute>
        {/* <Redirect to="/" /> */}
        <TheFooter />
      </>
    );
  };

  // add memoization here: https://youtu.be/lhMKvyLRWo0?t=428 for the provider value

  return (
    <UserProfileCtx.Provider
      value={{
        guid: userProfileInfo?.guid,
        nickname: userProfileInfo?.nickname,
        email: userProfileInfo?.email,
        setUserProfileInfo: (args: UserProfile) => {
          setUserProfileInfo((prev) => args);
        },
      }}
    >
      <Router>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route component={authRoutes} />
        </Switch>
      </Router>
    </UserProfileCtx.Provider>
  );
};

export default App;
