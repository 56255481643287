import { Button, makeStyles } from '@material-ui/core';
import React from 'react';
import { YahooIcon } from '../../icons';
import * as Styled from './styled';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '8px 8px 8px 0',
    paddingTop: '7.5px',
    // '& > *': {
    //   margin: '8px 8px 8px 0',
    //   paddingTop: '7.5px',
    //   display: 'flex',
    //   justifyContent: 'center',
    // },
  },
  circle: {
    color: 'var(--color-white)',
  },
}));

interface LoginFormProps {}

const LoginForm: React.FC<LoginFormProps> = (props: LoginFormProps) => {
  const classes = useStyles();

  const hasExpiredCookie =
    document.location.search.indexOf('expired-cookie') !== -1;

  return (
    <Styled.LoginFormWrapper>
      <h1 style={{ fontFamily: 'var(--font-style-logo)', fontWeight: 300 }}>
        Stucatz Analytics
      </h1>
      <div className={classes.root}>
        <Button
          variant="contained"
          color="secondary"
          style={{
            backgroundColor: 'var(--color-yahoo)',
            color: 'var(--color-white)',
            height: '65px',
            width: '200px',
            margin: '10px',
          }}
          href={`/api/auth/yahoo`}
        >
          Sign In With
          <YahooIcon color="#FFF" width={30} style={{ paddingLeft: '15px' }} />
        </Button>
        {hasExpiredCookie && (
          <i style={{ color: '#FF0000' }}>Cookie expired. Login again.</i>
        )}
      </div>
      <div>devPasta &#169; 2021</div>
    </Styled.LoginFormWrapper>
  );
};

export default LoginForm;
