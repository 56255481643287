import styled from 'styled-components';
import { NavLayoutProps } from './';

export const NavLayout = styled.nav<NavLayoutProps>`
  position: fixed;
  height: 100vh;
  right: 0;
  ${(props) => (props.isOpen ? 'width: 400px;' : 'width: 0;')}
  background-color: var(--color-grey);
  transition: width 200ms ease-in-out;
  overflow: auto;
  z-index: 888;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%),
    0 1px 5px 0 rgb(0 0 0 / 20%);

  @media (max-width: 800px) {
    ${(props) => (props.isOpen ? 'width: 100%' : 'width: 0;')}
  }
`;

export const NavItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 75px;
`;

export const NavItem = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 0;
  align-items: center;
  :hover {
    background-color: var(--color-white);
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%),
      0 1px 5px 0 rgb(0 0 0 / 20%);
  }
`;

// export const NavItem = styled.div`
//   display: flex;
//   padding: 10px 20px;
//   :hover {
//     background-color: var(--color-white);
//     color: var(--color-black);
//   }
// `;
