import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

export interface PrivateRouteProps extends RouteProps {}

const isAuthenticated = document.cookie.indexOf('stusesh') !== -1;

export const PrivateRoute: React.FC<PrivateRouteProps> = ({
  children,
  component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={() => {
        return isAuthenticated ? (
          children
        ) : (
          <Redirect to="/?err_expired-cookie;" />
        );
      }}
    />
  );
};
