import React from 'react';

export const sesh = {
  get: (key: string) => {
    const val = sessionStorage.getItem(key);
    return JSON.parse(val as string);
  },
  set: (key: string, el: any) => {
    const val = JSON.stringify(el);
    sessionStorage.setItem(key, val);
  },
};

export const batch = async <T>(
  batchSize: number,
  collection: T[],
  mapFxn: (item: T, idx: number) => Promise<T>,
) => {
  const settledCollection: T[] = [];

  for (let i = 0; i < collection.length; i += batchSize) {
    console.log(`Batch chunk starting: ${i}`);

    const collectionChunk = collection
      .slice(i, i + batchSize)
      .map((item: T, idx: number) => {
        return mapFxn.call(null, item, idx);
      });

    await Promise.all(collectionChunk)
      .then((entity) => {
        settledCollection.push(...entity);
      })
      .catch((err) => {
        throw new Error(`Promises Collect Error: ${err}`);
      });
  }

  return settledCollection;
};

export const inputDebounce = (
  fxn: (args: React.ChangeEvent<HTMLInputElement>) => void,
) => {
  let timer: NodeJS.Timeout;

  return (args: React.ChangeEvent<HTMLInputElement>) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fxn(args);
    }, 1000);
  };
};

export const debounce = (fxn: () => void) => {
  let timer: NodeJS.Timeout;

  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fxn.apply(this);
    }, 1000);
  };
};

export const stuFetch = async <T>(
  url: string,
  options?: RequestInit,
): Promise<T> => {
  return fetch(url, options ? options : { method: 'GET' }).then((res) => {
    if (res.redirected) {
      window.location.href = res.url;
    }

    if (res.ok) {
      return res.json();
    } else {
      return Promise.reject({
        isSuccess: res.ok,
        type: 'stuFetch Failure',
        code: res.status,
        body: res,
      });
    }
  });
};
