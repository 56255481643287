import styled from 'styled-components';

export const ModuleWrapper = styled.section`
  display: flex;
  padding-top: 75px;
  padding-bottom: 50px;
  min-height: 100vh;
`;

export const ModuleBody = styled.section`
  width: 100%;
  padding: 0 30px;
  overflow: auto;
  @media (max-width: 800px) {
    padding: 0 20px;
  }
`;
