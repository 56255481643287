import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

const styles = {
  tableCell: { minWidth: '60px', textAlign: 'center' } as React.CSSProperties,
  tableCell_teamName: {
    minWidth: '100px',
    textAlign: 'center',
    left: '0',
    position: 'sticky',
    background: '#fff',
  } as React.CSSProperties,
  paper: {
    width: '100%',
    margin: '10px 0',
    overflow: 'auto',
  },
};

export const MatchupTable: React.FC<MatchupTableProps> = ({
  userScoreboardDetail,
  opponentScoreboardDetails,
}) => {
  const [rows, setRows] = useState<JSX.Element[]>();

  const createStatCell = (oppStat: ScoreboardStat): StatCellDetail => {
    const userStatValue = +userScoreboardDetail.scoreboardStatCollection.find(
      (userStat) => userStat.statId === oppStat.statId,
    )!.value;
    const oppStatValue = +oppStat.value;
    const operator = oppStat.operator;

    if (userStatValue === oppStatValue) {
      return {
        outcome: 'tie',
        tableCell: (
          <TableCell style={styles.tableCell}>{oppStat.value}</TableCell>
        ),
      } as StatCellDetail;
    } else if (operator === 'gt') {
      const isWinner = userStatValue > oppStatValue;

      return isWinner
        ? ({
            outcome: 'win',
            tableCell: (
              <TableCell className="win-stat" style={styles.tableCell}>
                {oppStat.value}
              </TableCell>
            ),
          } as StatCellDetail)
        : ({
            outcome: 'loss',
            tableCell: (
              <TableCell className="loss-stat" style={styles.tableCell}>
                {oppStat.value}
              </TableCell>
            ),
          } as StatCellDetail);
    } else {
      const isWinner = userStatValue < oppStatValue;

      return isWinner
        ? ({
            outcome: 'win',
            tableCell: (
              <TableCell className="win-stat" style={styles.tableCell}>
                {oppStat.value}
              </TableCell>
            ),
          } as StatCellDetail)
        : ({
            outcome: 'loss',
            tableCell: (
              <TableCell className="loss-stat" style={styles.tableCell}>
                {oppStat.value}
              </TableCell>
            ),
          } as StatCellDetail);
    }
  };

  const createResultString = (result: MatchupResult): string => {
    if (result.win > result.loss) {
      return `W ${result.win}-${result.loss}-${result.tie}`;
    } else if (result.win === result.loss) {
      return `T ${result.win}-${result.loss}-${result.tie}`;
    } else {
      return `L ${result.win}-${result.loss}-${result.tie}`;
    }
  };

  useEffect(() => {
    const newRows: JSX.Element[] = [];

    opponentScoreboardDetails.forEach((op) => {
      const result: MatchupResult = {
        win: 0,
        loss: 0,
        tie: 0,
      };

      const statRows = op.scoreboardStatCollection.map((stat) => {
        const statCellDetail = createStatCell(stat);
        ++result[statCellDetail.outcome];
        return statCellDetail.tableCell;
      });

      const matchupResult = createResultString(result);

      newRows.push(
        <TableRow>
          <TableCell key="team-name" style={styles.tableCell_teamName}>
            {op.isOpponent ? <strong>*{op.teamName}</strong> : op.teamName}
          </TableCell>
          <TableCell key="matchup-result">{matchupResult}</TableCell>
          {statRows}
        </TableRow>,
      );
    });

    setRows(newRows);
  }, [opponentScoreboardDetails]);

  return (
    <Paper style={styles.paper} elevation={4}>
      <TableContainer className="matchup-table-container">
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell
                key="team-name"
                style={{ zIndex: 100, ...styles.tableCell_teamName }}
              >
                {userScoreboardDetail.scoreboardStatCollection
                  ? 'Team Name'
                  : 'No data to show'}
              </TableCell>
              {userScoreboardDetail.scoreboardStatCollection && (
                <TableCell key="matchup-result" style={styles.tableCell}>
                  Result
                </TableCell>
              )}
              {userScoreboardDetail.scoreboardStatCollection &&
                userScoreboardDetail.scoreboardStatCollection.map((stat) => {
                  return (
                    <TableCell
                      key={`${stat.statName + stat.value}`}
                      style={styles.tableCell}
                    >{`${stat.statName} (${stat.value})`}</TableCell>
                  );
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows &&
              rows.map((row) => {
                return row;
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
