import React from 'react';
import * as Styled from './styled';

const TheFooter: React.FC = (props) => {
  return (
    <Styled.FooterWrapper>
      <div title="Never forget he who weaves you baskets">
        devPasta &#169; 2021
      </div>
    </Styled.FooterWrapper>
  );
};

export default TheFooter;
