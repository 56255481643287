import React from 'react';
import LoginForm from '../../components/forms/login';
import * as Layout from '../../layouts/sock-layout';

const Login: React.FC = (props) => {
  return (
    <Layout.SockLayout>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <LoginForm />
      </div>
    </Layout.SockLayout>
  );
};

export default Login;
