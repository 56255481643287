import { CircularProgress } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { LeagueCard } from '../../components/containers/cards';
import { UserProfileCtx } from '../../context/user';
import * as Layout from '../../layouts/module-layout';
import { sesh, stuFetch } from '../../utils';

const getManagerProfile = async (): Promise<ManagerProfile> => {
  const managerProfile: ManagerProfile = sesh.get('managerctx');

  if (managerProfile) {
    return managerProfile;
  } else {
    return stuFetch<StucatzHttpRes>('/api/fantasy/manager-profile').then(
      (data: StucatzHttpRes) => {
        if (!data.isSuccess) throw new Error(data.body);

        sesh.set('managerctx', data.body);
        return data.body as ManagerProfile;
      },
    );
  }
};

const ManagerModule: React.FC = (props) => {
  const userProfileCtx = useContext(UserProfileCtx);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [leagueCollection, setLeagueCollection] = useState<League[]>([]);
  const [userProfile, setUserProfile] = useState<UserProfile>({
    nickname: '',
    email: '',
    guid: '',
  } as UserProfile);

  useEffect(() => {
    (async () => {
      await getManagerProfile()
        .then((managerProfile) => {
          setUserProfile((prev) => {
            return {
              guid: managerProfile.leagueCollection[0].managerInfo.guid,
              nickname: managerProfile.leagueCollection[0].managerInfo.nickname,
              email: managerProfile.leagueCollection[0].managerInfo.email,
            };
          });
          const leaguesChrono = managerProfile.leagueCollection.reverse();
          setLeagueCollection((prev) => leaguesChrono);
          setIsLoading((prev) => !prev);
        })
        .catch((err) => {
          console.error(
            `Stucatz Derp - Failed getting/setting managerctx | ${err}`,
          );
        });
    })();
  }, []);

  return (
    <Layout.ModuleLayout>
      <h2>Manager Hub</h2>
      <section style={{ margin: '15px 0' }}>
        <h3>User Profile</h3>
        <p>
          <strong>Name: </strong>
          {`${userProfile?.nickname} (${userProfile?.email})`}
        </p>
        <p>
          <i>
            <strong>GUID: </strong>
            {`${userProfile?.guid}`}
          </i>
        </p>
        {isLoading && <CircularProgress size={25} />}
      </section>
      <section style={{ margin: '15px 0' }}>
        <h3>League History</h3>
        {isLoading && <CircularProgress size={25} />}
        {leagueCollection.map((league) => {
          return (
            <LeagueCard
              key={league.season + league.leagueId}
              leagueName={league.name}
              leagueUrl={league.url}
              leagueKey={league.leagueKey}
              leagueType={league.leagueType}
              scoringType={league.scoringType}
              numTeams={league.numTeams}
              season={league.season}
              startDate={league.startDate}
              endDate={league.endDate}
              isFinished={league.isFinished}
              teamName={league.teamInfo.name}
              numberOfMoves={league.teamInfo.number_of_moves}
              numberOfTrades={league.teamInfo.number_of_trades}
              teamUrl={league.teamInfo.url}
              feloScore={league.managerInfo.felo_score}
              feloTier={league.managerInfo.felo_tier}
            />
          );
        })}
      </section>
    </Layout.ModuleLayout>
  );
};

export default ManagerModule;
