import styled from 'styled-components';

export const HeaderWrapper = styled.header`
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 75px;
  top: 0;
  margin: 0;
  background-color: var(--color-black);
  z-index: 999;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%),
    0 1px 5px 0 rgb(0 0 0 / 20%);
`;

export const HeaderLogo = styled.h2`
  color: var(--color-white);
  font-family: var(--font-style-logo);
  padding: 15px;
  margin: 0;
  font-weight: 300;
`;
