import styled from 'styled-components';

export const FooterWrapper = styled.footer`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75px;
  bottom: 0;
  margin: 0;
  background-color: var(--color-black);
  color: var(--color-white);
`;
