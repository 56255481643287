import React from 'react';
import * as Styled from './styled';

export interface SockStripeProps {
  height: number;
}

export const SockLayout: React.FC = ({ children }) => {
  return (
    <div>
      <Styled.SockLayout>
        <div>
          <Styled.SockStripe height={20} />
          <Styled.SockStripe height={60} />
          <Styled.SockStripe height={20} />
        </div>

        <div>
          <Styled.SockStripe height={20} />
          <Styled.SockStripe height={60} />
          <Styled.SockStripe height={20} />
        </div>
      </Styled.SockLayout>
      {children}
    </div>
  );
};
