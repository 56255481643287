import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { MatchupTable } from '../../components/table/matchup-table';
import * as Layout from '../../layouts/module-layout';
import { sesh, stuFetch } from '../../utils';
import * as Styled from './styled';

const styles = {
  formControls: { width: '300px', margin: '5px 15px 5px 0' },
};

const MatchupsModule: React.FC = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [leagueCollection, setLeagueCollection] = useState<League[]>([]);
  const [scoreboards, setScoreboards] = useState({
    userScoreboard: {} as ScoreboardDetail,
    opponentScoreboards: [] as ScoreboardDetail[],
  });
  const [scoreboardOptions, setScoreboardOptions] = useState<ScoreboardOptions>(
    {
      league: '',
      week: '',
      weeksCollection: [],
    },
  );

  const handlers = {
    onLeagueSelect: async (event) => {
      setIsLoading(true);
      const managerProfileCtx: ManagerProfile = sesh.get('managerctx');
      const selectedLeague = managerProfileCtx.leagueCollection.find(
        (league) => league.leagueKey === event.target.value,
      ) as League;

      const seasonStartWeek = +selectedLeague.startWeek;
      const seasonCurrentWeek = +selectedLeague.currentWeek;
      const seasonWeekCollection: number[] = [];

      for (let i = seasonStartWeek; i <= seasonCurrentWeek; i++) {
        seasonWeekCollection.push(i);
      }

      await stuFetch<ScoreboardInfoRes>(
        `/api/fantasy/scoreboard/${event.target.value}/${seasonStartWeek}`,
      )
        .then((data) => {
          const scoreboardInfo = data.body as ScoreboardInfo;

          const currentUserScoreboard =
            scoreboardInfo.scoreboardDetailCollection.find((detail) => {
              return detail.teamGuid === managerProfileCtx.guid;
            });
          const opponentScoreboards =
            scoreboardInfo.scoreboardDetailCollection.filter((detail) => {
              return detail.teamGuid !== managerProfileCtx.guid;
            });

          setScoreboards((prev) => {
            return {
              userScoreboard: currentUserScoreboard as ScoreboardDetail,
              opponentScoreboards: opponentScoreboards as ScoreboardDetail[],
            };
          });
          setScoreboardOptions((prev) => {
            return {
              week: selectedLeague.startWeek,
              league: event.target.value as string,
              weeksCollection: seasonWeekCollection,
            };
          });

          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    },
    onWeekSelect: async (event) => {
      setIsLoading(true);
      await stuFetch<ScoreboardInfoRes>(
        `/api/fantasy/scoreboard/${scoreboardOptions.league}/${event.target.value}`,
      )
        .then((data) => {
          const scoreboardInfo = data.body as ScoreboardInfo;
          const managerProfileCtx: ManagerProfile = sesh.get('managerctx');

          const currentUserScoreboard =
            scoreboardInfo.scoreboardDetailCollection.find((detail) => {
              return detail.teamGuid === managerProfileCtx.guid;
            });
          const opponentScoreboards =
            scoreboardInfo.scoreboardDetailCollection.filter((detail) => {
              return detail.teamGuid !== managerProfileCtx.guid;
            });

          setScoreboardOptions((prev) => {
            return {
              ...prev,
              week: event.target.value as string,
            };
          });
          setScoreboards((prev) => {
            return {
              userScoreboard: currentUserScoreboard as ScoreboardDetail,
              opponentScoreboards: opponentScoreboards as ScoreboardDetail[],
            };
          });

          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    },
  };

  useEffect(() => {
    const managerProfileCtx: ManagerProfile = sesh.get('managerctx');
    if (managerProfileCtx) {
      const headLeagues = managerProfileCtx.leagueCollection
        .filter((league) => {
          return league.scoringType === 'head';
        })
        .reverse();
      setLeagueCollection((prev) => headLeagues);
    }
  }, []);

  return (
    <Layout.ModuleLayout>
      <h2>Matchups</h2>
      <h3>Weekly Breakdown Matrix</h3>
      <section id="matchup-controls">
        <p>Select your league and week:</p>
        <Styled.ControlsWrapper>
          <FormControl variant="outlined" style={styles.formControls}>
            <InputLabel id="league-picker-label-matchups">League</InputLabel>
            <Select
              labelId="league-picker-matchups"
              id="league-picker-select-matchups"
              label="League"
              defaultValue=""
              onChange={handlers.onLeagueSelect}
            >
              {leagueCollection.map((league: League) => {
                return (
                  <MenuItem key={league.leagueKey} value={league.leagueKey}>
                    {league.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl variant="outlined" style={styles.formControls}>
            <InputLabel id="week-picker-label-matchups">Week</InputLabel>
            <Select
              labelId="week-picker-matchups"
              id="week-picker-select-matchups"
              label="Week"
              disabled={scoreboardOptions.league === ''}
              value={scoreboardOptions.week}
              onChange={handlers.onWeekSelect}
            >
              {scoreboardOptions.weeksCollection.map((e, idx) => {
                return (
                  <MenuItem key={`sb-week-${e}`} value={e}>
                    {e}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {isLoading && <CircularProgress size={30} />}
        </Styled.ControlsWrapper>
      </section>
      <section>
        <MatchupTable
          userScoreboardDetail={scoreboards.userScoreboard}
          opponentScoreboardDetails={scoreboards.opponentScoreboards}
        />
      </section>
    </Layout.ModuleLayout>
  );
};

export default MatchupsModule;
