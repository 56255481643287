import { Card, CardContent } from '@material-ui/core';
import React from 'react';
import { LinkIcon } from '../../../icons';

export const LeagueCard: React.FC<LeagueCardProps> = (props) => {
  return (
    <Card style={{ maxWidth: 600, margin: '20px 0' }}>
      {/* <CardMedia
        component="img"
        height="140"
        image={props.logo_url as string}
        alt="green iguana"
      /> */}
      <CardContent>
        <h4>
          [{props.season}] {props.leagueName}
          <LinkIcon
            link={props.leagueUrl}
            width={20}
            color="#2200CC"
            style={{ padding: '0 7.5px' }}
          />
        </h4>
        <p>
          <strong>Team Name: </strong>
          {props.teamName}
          <LinkIcon
            link={props.teamUrl}
            width={15}
            color="#2200CC"
            style={{ padding: '0 7.5px' }}
          />
        </p>
        <p>
          <strong>League Key: </strong>
          {`${props.leagueKey} (${props.leagueType})`}
        </p>
        <p>
          <strong>Scoring Type: </strong>
          {props.scoringType}
        </p>
        <p>
          <strong>Teams: </strong>
          {props.numTeams}
        </p>
        <p>
          <strong>Moves / Trades: </strong>
          {`${props.numberOfMoves} / ${props.numberOfTrades}`}
        </p>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <i>{`${props.feloTier} (${props.feloScore})`}</i>
          {!props.isFinished ? (
            <i style={{ color: '#07A120' }}>Active</i>
          ) : (
            <i style={{ color: '#696969' }}>Inactive</i>
          )}
        </div>
      </CardContent>
    </Card>
  );
};
