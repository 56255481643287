import React from 'react';
import * as Layout from '../../layouts/module-layout';

const TradesModule: React.FC = (props) => {
  return (
    <Layout.ModuleLayout>
      <h2>Trades</h2>
      <div
        style={{
          textAlign: 'center',
        }}
      >
        <h4>&#128679;Under Construction&#128679;</h4>
        <img
          src="https://stucatz-assets.s3.us-east-2.amazonaws.com/img/marner-crybaby.png"
          style={{
            borderRadius: '50%',
            height: '250px',
            border: '3px solid #000',
          }}
          alt="marner-cry"
        />
        <h4>It's ok to be upset</h4>
      </div>
    </Layout.ModuleLayout>
  );
};

export default TradesModule;
