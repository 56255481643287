import React from 'react';
import './styles.css';

interface BurgerProps {
  isOpen: boolean;
  onClick: () => void;
}

export const Burger: React.FC<BurgerProps> = (props) => {
  return (
    <div className="burger-wrapper">
      <div
        id="burger-icon"
        className={`${props.isOpen && 'burger-active'}`}
        onClick={props.onClick}
      >
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};
