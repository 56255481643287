import React, { useEffect } from 'react';
import * as Styled from './styled';
// import { ScrollToTop } from '../../components/router-utils';

export const ModuleLayout: React.FC = ({ children }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Styled.ModuleWrapper>
      <Styled.ModuleBody>{children}</Styled.ModuleBody>
    </Styled.ModuleWrapper>
  );
};
